import skrollr from 'skrollr';

let skrollrInstance = null;

function initSkrollrOnCondition() {
  const isSkrollrPage = document.body.classList.contains('page-with-skrollr');
  const shouldInitializeSkrollr = window.innerWidth >= 1280;

  if (isSkrollrPage) {
    if (shouldInitializeSkrollr && !skrollrInstance) {
      // Initialize Skrollr
      skrollrInstance = skrollr.init();
    } else if (!shouldInitializeSkrollr && skrollrInstance) {
      // Destroy Skrollr
      skrollrInstance.destroy();
      skrollrInstance = null;
    }
  }
}

// Add event listener for resizing
window.addEventListener('resize', initSkrollrOnCondition);

// Initial check
initSkrollrOnCondition();
