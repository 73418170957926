const marquee = () => {
  const marquees = document.querySelectorAll('[data-marquee]');
  if (marquees.length === 0) return;

  marquees.forEach((marquee) => {
    const track = marquee.querySelector('[data-marquee-track]');
    if (!track) return;

    const clonedTrack = track.cloneNode(true);
    marquee.appendChild(clonedTrack);
  });
};

// Run marquee function when DOM is ready
document.addEventListener('DOMContentLoaded', () => {
  marquee();
});

export default marquee;
